@import "../css/_brand_colors.scss";
@import "../css/globals";


.modal {
    background: #000000B3;
}

.layout {
    width: 580px;
    height: 60px;
}

.body {
    background-color: #FFF;
}

.footer {
    background-color: #FFF;
}