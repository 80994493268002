import ReactDOM from "react-dom";
import * as React from "react";
import { useRef, useState } from "react";

import { StrictMode } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LazyDiv } from "./widgets/lazy_div";

import * as SentryWrapper from "./utils/sentry_wrapper";
import * as Sentry from "@sentry/react";
import { withRouter } from "./utils/withRouter";
import { initializeSentry } from "./utils/sentry_utils";
import { queryClient } from "./lib/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
import { setAutoFreeze } from "immer";
import { routes } from "./utils/routes";
import BrowserWarningPopup from "./components/alerts/BrowserWarningPopup";


// TODO: Immer automatically freezes object. It protects against accidental mutations. However,
// we still mutate object directly in some places. We should remove this line after we fix all
// of them
setAutoFreeze(false);

//Routes are listed in alphabetical order and grouped by folders
export default function App() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  const RoutedComponent = withRouter(SentryRoutes);
  const confirmationRef = useRef();
  const [showBrowserWarning, setShowBrowserWarning] = useState(true);

  const handleCloseBrowserWarning = () => {
    setShowBrowserWarning(false);
  };

  return (
    <BrowserRouter>
      {showBrowserWarning && <BrowserWarningPopup
        modalRef={(modalRef) => (confirmationRef.current = modalRef)}
        onClose={handleCloseBrowserWarning}
      />}

      <RoutedComponent>
        {routes.map((route) => {
          const {path, component, id, className, ignoreLazy} = route;
          return ignoreLazy ? (
            <Route path={path} element={component} />
          ) : (
            <Route path={path} element={
              <LazyDiv id={id} className={className} Render={component} />
            }
            />
          );
        })}
      </RoutedComponent>
    </BrowserRouter>
  );
}

initializeSentry();

const AppWrappedWithSentry = SentryWrapper.wrap(App);
ReactDOM.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppWrappedWithSentry />
    </QueryClientProvider>
  </StrictMode>
  , document.getElementById("root"));